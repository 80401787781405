
import { useStore } from "vuex";
import { defineComponent, computed, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
export default defineComponent({
  props: ["isLoading"],
  components: {
    Datatable,
  },
  setup() {
    const check = ref(false);
    const toString = ref("");
    const dialogTableVisible = ref(false);
    const isLoadingPage = ref(true);
    const reload = ref(false);
    const imgPopup = ref(false);
    const deleteData = ref("");
    const index = ref(0);
    const router = useRouter();
    const route = useRoute();
    const tableHeader = ref([
      {
        name: "No.",
        key: "no",
        sortable: false,
        align: "center",
      },
      {
        name: "Bank Name",
        key: "bank_name_en",
        sortingField: "status.label",
        sortable: true,
        align: "center",
      },
      {
        name: "Number",
        key: "bank_manager_bank_number",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Name",
        key: "bank_manager_customer_name",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Status",
        key: "bank_manager_status",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Action",
        key: "actions",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
    ]);
    const searchForm = computed(() => {
      return store.state.BankModule.filterBankListForm;
    });
    const bankList = computed(() => {
      return store.state.BankModule.get_bank;
    });
    const get_create_bank = computed(() => {
      return store.state.BankModule.get_create_bank;
    });
    async function toDelete(id) {
      toString.value = id.toString();
      isLoadingPage.value = true;
      reload.value = true;
      store.commit("setLoadingGlobal", true);
      const result = await store.dispatch(
        `BankModule/${Actions.DELETE_BANK}`,
        toString.value
      );
      if (result == false) return;
      await store.dispatch(`BankModule/${Actions.FETCH_LIST_BANK}`);
      imgPopup.value = false;
      store.commit("setLoadingGlobal", false);
      isLoadingPage.value = false;
    }
    async function Popupdelete(item) {
      imgPopup.value = true;
      deleteData.value = item;
    }
    const store = useStore();
    const TablebankList = computed(() => {
      return store.getters["BankModule/bankList"].filter((val) => {
        return (
          val.bank_manager_bank.bank_name_en
            .toLowerCase()
            .includes(searchForm.value.bank_text.toLowerCase()) ||
          val.bank_manager_bank.bank_name_th
            .toLowerCase()
            .includes(searchForm.value.bank_text.toLowerCase()) ||
          val.bank_manager_bank_number
            .toLowerCase()
            .includes(searchForm.value.bank_text.toLowerCase()) ||
          val.bank_manager_customer_name
            .toLowerCase()
            .includes(searchForm.value.bank_text.toLowerCase())
        );
      });
    });
    return {
      moment,
      tableHeader,
      searchForm,
      route,
      check,
      isLoadingPage,
      toDelete,
      reload,
      dialogTableVisible,
      bankList,
      toString,
      imgPopup,
      Popupdelete,
      deleteData,
      index,
      TablebankList,
    };
  },
});
